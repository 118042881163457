/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTeamMemberListQuery } from '@gen2/api/team-member/hooks';
import { useAuth } from '@gen2/hooks';
import { PermissionsKeys } from '@gen2/types/permissions';
import { SearchCondition } from '@gen2/types/search-condition';
import { TBasicUser } from '@gen2/types/user';
import { uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { useSendInviteStore } from './store';

export const usePointOfContactAutocomplete = () => {
  const { user } = useAuth();
  const initialSearchCondition: SearchCondition = useMemo(
    () => ({
      'sort[column]': 'first_name',
      'sort[order]': 'asc',
      'filter[permission]': [
        PermissionsKeys.CREATE_INVITE,
        PermissionsKeys.UPDATE_INVITE,
      ],
      per_page: 10,
      page: 1,
    }),
    [],
  );
  const [selectedPointOfContact, setSelectedPointOfContact] = useState<
    TBasicUser[]
  >([]);

  const { invite } = useSendInviteStore();
  const [search, setSearch] = useState('');
  const [debouncedValue] = useDebounceValue(search, 300);
  const [searchCondition, setSearchCondition] = useState(
    initialSearchCondition,
  );

  // ui optiomization for text due to Debouncing Delay
  // shows noOptionsText before the search results are available
  const [isSearching, setIsSearching] = useState(false);

  const [showAvatar, setShowAvatar] = useState(true);

  // point of contact (user list)
  const { data: users, isLoading: isPointofContactsLoading } =
    useTeamMemberListQuery(searchCondition);

  const mixedPointofContacts = useMemo(() => {
    const myself = {
      id: user?.id ?? '',
      email: user?.email ?? '',
      first_name: user?.first_name ?? '',
      last_name: user?.last_name ?? '',
      avatar_url: user?.avatar_url ?? '',
      isMyself: true,
    };
    // mixed myself, selected point of contact with returned users from api
    const selected = selectedPointOfContact?.[0];
    const combinedContacts = uniqBy(
      [myself, ...(selected ? [selected] : []), ...(users?.data || [])],
      'id',
    );

    // Sort all contacts except `myself` by `first_name`
    const sortedContacts = [
      myself,
      ...combinedContacts
        .slice(1)
        .sort((a, b) => a.first_name.localeCompare(b.first_name)),
    ];
    return sortedContacts as TBasicUser[];
  }, [user, selectedPointOfContact, users]);

  // update default selected point of contact when invite is stored
  useEffect(() => {
    if (invite?.from_user) {
      setSelectedPointOfContact([invite.from_user]);
    }
  }, [invite]);

  useEffect(() => {
    if (debouncedValue) {
      setSearchCondition((prev) => ({
        ...prev,
        'filter[search]': debouncedValue,
      }));
    } else {
      setSearchCondition(initialSearchCondition);
    }
  }, [debouncedValue, initialSearchCondition]);

  return {
    mixedPointofContacts,
    isPointofContactsLoading,
    selectedPointOfContact,
    setSelectedPointOfContact,
    setSearch,
    showAvatar,
    setShowAvatar,
    isSearching,
    setIsSearching,
  };
};
