import {useInviteQuery} from "@gen2/api/invites/hooks";
import {
  HeaderTitle, StyledHeader,
  StyledHeaderActions, StyledViewer, StyledWebViewerBox
} from "@gen2/app/components/invite-request/variant/live-form/peview-web-viewer-modal.styled";
import {useSendInviteStore} from "@gen2/app/invites/send-invites/store";
import {TContact} from '@gen2/types/contact';
import {Button, Dialog} from "@mui/material";
import cn from "classnames";
import PSPDFKit, { Instance } from "pspdfkit";
import { useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useEffectOnce} from "usehooks-ts";
import {onDragStart} from "./nutrient/sidePanel/helpers";
import SidePanel from "./nutrient/sidePanel/sidePanel";
import {handleAnnotationCreation, handleAnnotationDelete, TOOLBAR_ITEMS} from "./nutrient/utils/common-helpers";
import getAnnotationRenderers from "./nutrient/utils/customAnnotationRenderer";
import customizeUIForInitials from "./nutrient/utils/customizeUIForInitials";
import handleDrop from "./nutrient/utils/dropAnnotations";
import {userChange, onPressDuplicate} from "./nutrient/utils/helpers";
import {AnnotationTypeEnum as importedAnnotationTypeEnum, User} from "./nutrient/utils/types";

const AnnotationTypeEnum = importedAnnotationTypeEnum;

export interface FormViewerModalProps {
  fileId: string;
  fileName: string;
  onClose: () => void;
  open: boolean;
  fileUrl: string;
}

export const FormViewerModal = ({
                                  fileName,
                                  onClose,
                                  open,
                                  fileUrl,
                                }: FormViewerModalProps) => {

  const viewer = useRef(null);
  const [isHeaderInVisible, setIsHeaderInVisible] = useState(false);

  const {inviteId} = useParams<{
    inviteId: string;
  }>();

  const siStore = useSendInviteStore();
  const {data: invite} = useInviteQuery(inviteId || siStore?.invite?.id, {
    enabled: Boolean(inviteId || siStore?.invite?.id),
  });
  const contacts: TContact[] = useMemo(() => invite?.contacts || [], [invite]);
  const [instance, setInstance] = useState<any>(null);

  // Solution：solely to work around an issue that only appears in Development mode,
  // forcing WebViewer Initialization Only Occurs Once in Development build -- the Strict Mode
  const beenInitialised = useRef<boolean>(false);
  // State to store the users. Master is the default user for now who can add fields in the doc.

  // State to store the current signee i.e the user who is currently selected for which the field will be added
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const [currSignee, setCurrSignee] = useState(contacts[0]);
  const currSigneeRef = useRef(currSignee);
  currSigneeRef.current = currSignee;

  // State to store the current user i.e the user who is currently selected / Loggedin
  const [currUser, setCurrUser] = useState<TContact>(contacts[0]);
  const currUserRef = useRef(currUser);
  currUserRef.current = currUser;


  // State to store the current page index
  const [onPageIndex, setOnPageIndex] = useState<number>(0);
  const onPageIndexRef = useRef(onPageIndex);
  onPageIndexRef.current = onPageIndex;

  const [readyToSign, setReadyToSign] = useState<boolean>(false);

  // For Custom signature / initial field appearance
  const mySignatureIdsRef = useRef([]);
  const [signatureAnnotationIds, setSignatureAnnotationIds] = useState<string[]>([]);

  // For Custom Add Signature / Intitial field appearance
  const [sessionSignatures, setSessionSignatures] = useState<any>([]);
  const [sessionInitials, setSessionInitials] = useState<any>([]);

  function onDragEnd(event: React.DragEvent<HTMLDivElement>) {
    (event.target as HTMLDivElement).style.opacity = "1";
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const [isTextAnnotationMovable, setIsTextAnnotationMovable] = useState(false);
  const isTextAnnotationMovableRef = useRef(isTextAnnotationMovable);
  isTextAnnotationMovableRef.current = isTextAnnotationMovable;

  // Tracking whether add Signature/Initial UI
  let isCreateInitial = false;
  const digitallySigned: any = null;
  let trackInst: any = null;
  const loadPSPDFKit = async (el?: HTMLElement) => {
    if (PSPDFKit && fileUrl && el && !beenInitialised.current) {
      PSPDFKit.unload(el);

      try {
        const {UI: {createBlock, Recipes, Interfaces}} = PSPDFKit;
        const inst = await PSPDFKit.load({
          container: el,
          document: fileUrl,
          baseUrl: `${window.location.protocol}//${window.location.host}/assets/lib/pspdfkit/`,
          toolbarItems: [...TOOLBAR_ITEMS],
          disableTextSelection: false,
          styleSheets: [`/assets/lib/pspdfkit/viewer.css`],
          isEditableAnnotation: (annotation) => !annotation.isSignature,
          ui: {
            [Interfaces.CreateSignature]: ({props}) => ({
              content: createBlock(
                Recipes.CreateSignature,
                props,
                ({ui}) => {
                  if (isCreateInitial) ui = customizeUIForInitials(ui);
                  return ui.createComponent();
                }
              ).createComponent(),
            }),
          },
          customRenderers: {
            Annotation: ({annotation}) => {
              return getAnnotationRenderers({annotation, currSignee});
            },
          },
        });
        trackInst = inst;
        setInstance(inst);
        configureInstanceEvents(inst); // Configure instance-specific events
      } catch (error) {
        console.error('Error loading PSPDFKit:', error);
      }
    } else {
      setTimeout(() => loadPSPDFKit(viewer.current!), 100);
    }
  };

  // Unload PSPDFKit instance on unmount
  const cleanupPSPDFKit = async () => {
    if (instance && viewer.current) {
      console.log("Unloading PSPDFKit instance.");
      PSPDFKit.unload(viewer.current);
      setInstance(null); // Set instance to null to allow reinitialization
    }
  };

  useEffectOnce(() => {
    loadPSPDFKit(viewer.current!);

    return () => {
      if (viewer.current) {
        cleanupPSPDFKit();
        beenInitialised.current = false;
      }
    };
  });


  const configureInstanceEvents = (inst: Instance) => {
    // Additional configurations and event listeners for the PSPDFKit instance

    inst.setViewState((viewState) =>
      viewState.set(
        'showSignatureValidationStatus',
        PSPDFKit.ShowSignatureValidationStatusMode.IF_SIGNED
      )
    );

    inst.addEventListener('viewState.currentPageIndex.change', (page) =>
      setOnPageIndex(page)
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const cont = inst.contentDocument.host;
    cont.ondrop = async (e: any) =>
      handleDrop(e, inst, PSPDFKit, currSigneeRef, currUserRef, onPageIndexRef);

    inst.addEventListener('annotations.press', (event: { preventDefault?: any; annotation?: any; }) => {
      const { annotation } = event
      if (annotation.customData?.isInitial) {
        setSessionInitials((prev: any) => [...prev, annotation]);
        isCreateInitial = true;
      } else {
        setSessionSignatures((prev: any) => [...prev, annotation]);
        isCreateInitial = false;
      }

      if (!isTextAnnotationMovableRef.current && annotation instanceof PSPDFKit.Annotations.TextAnnotation) {
        event.preventDefault();
      }

      //If it's a text widget show duplicate in popup
        setTimeout(() => {
          const popupFooterDiv = inst.contentDocument.querySelector(".PSPDFKit-2wtzexryxvzwm2ffu1e1vh391u");
          const existingDuplicateButton = inst.contentDocument.querySelector(`.PSPDFKit-Form-Creator-Editor-Duplicate`);
          // Remove existing duplicate button if it exists
          if (existingDuplicateButton) {
            existingDuplicateButton.remove();
          }

          // Add duplicate button if it doesn't exist
          if(popupFooterDiv) {
            const duplicateButtonHTML = document.createElement("button");
            duplicateButtonHTML.innerHTML = "Duplicate";
            duplicateButtonHTML.className = "PSPDFKit-239sjtxdzvwfjbe3ass6aqfp77 PSPDFKit-7kqbgjh4u33aw27zwkrtu57p2e PSPDFKit-68w1xn1tjp178f46bwdhvjg7f1 PSPDFKit-Form-Creator-Editor-Duplicate "+annotation.Id;
            duplicateButtonHTML.onclick = async () =>{
              await onPressDuplicate(annotation, PSPDFKit, trackInst);
            }
            popupFooterDiv.appendChild(duplicateButtonHTML);
          }
        }, 1);

      if (!isTextAnnotationMovableRef.current && event.annotation instanceof PSPDFKit.Annotations.TextAnnotation) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        event.preventDefault();
      }
    });

    inst.addEventListener('storedSignatures.create', (annotation: any) => {
      if (isCreateInitial) {
        setSessionInitials((prev: any) => [...prev, annotation]);
      } else {
        setSessionSignatures((prev: any) => [...prev, annotation]);
      }
    });

    inst.addEventListener('annotations.load', (loadedAnnotations: any) =>
      handleLoadedAnnotations(loadedAnnotations, inst)
    );

    inst.addEventListener('annotations.create', (createdAnnotations: { get: (arg0: number) => any; }) => {
      handleAnnotationCreation(
        inst,
        createdAnnotations.get(0),
        mySignatureIdsRef,
        setSignatureAnnotationIds,
        currUser.email
      );
    });

    inst.addEventListener('annotations.delete', (deletedAnnotations: any) => {
      handleAnnotationDelete(inst, deletedAnnotations.get(0), currUser?.email);
      setSignatureAnnotationIds((ids) =>
        ids.filter((id) => id !== deletedAnnotations.get(0).id)
      );
    });

    inst.setViewState((viewState) =>
      viewState.set('interactionMode', PSPDFKit.InteractionMode.FORM_CREATOR)
    );
    setIsTextAnnotationMovable(true);
  };

  const handleLoadedAnnotations = async (annotations:any, inst: any) => {
    for (const annotation of annotations) {
      await handleAnnotationCreation(
        inst,
        annotation,
        mySignatureIdsRef,
        setSignatureAnnotationIds,
        currUser.email
      );
    }
  };

  return (
    <Dialog id="form-viewer-modal" fullScreen open={open} onClose={onClose}>
      <StyledHeader className={cn({hidden: isHeaderInVisible})}>
        <HeaderTitle>{fileName}</HeaderTitle>
        <StyledHeaderActions spacing={1} direction="row">
          <Button variant="outlined" color="tertiary" onClick={onClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </StyledHeaderActions>
      </StyledHeader>
      <StyledWebViewerBox>
        <SidePanel
          users={contacts}
          currUser={currUser}
          currSignee={currSignee}
          userChange={(user: User) => userChange(user, PSPDFKit, instance, setCurrUser)}
          onDragStart={(event: React.DragEvent<HTMLDivElement>, type: string) => onDragStart(event, type, currSignee, instance)}
          onDragEnd={onDragEnd}
          containerRef={viewer}
        />
        <StyledViewer ref={viewer} onDragOver={handleDragOver}/>
      </StyledWebViewerBox>
    </Dialog>
  );
};
