import {StyledFormTitle} from "@gen2/app/team-member/layout/team-member-create.styled";
import {TContact} from '@gen2/types/contact';
import {Box, Divider, MenuItem, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {AnnotationTypeEnum} from "../utils/types";
import {DraggableAnnotation} from "./draggableAnnotations/draggableAnnotations"; // Adjust the import path as needed

const defaultAssignee = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
};


const SidePanel = ({
                     users,
                     currSignee,
                     userChange,
                     onDragStart,
                     onDragEnd,
                   }: any) => {
  const initialAssignee = users.length > 0 ? users[0] : defaultAssignee;
  const [assignee, setAssignee] = useState(initialAssignee);

  return (
    <Box paddingX={2} paddingTop={2}>
      <Box>
        <StyledFormTitle style={{paddingBottom: '10px', paddingTop: '10px'}}>Apply Fields</StyledFormTitle>
        <Typography variant="body2" paddingBottom={2}>Drag & drop fields on the document</Typography>
        <Divider></Divider>
        <DraggableAnnotation
          type={AnnotationTypeEnum.TextField}
          label="Text Field"
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />
        <DraggableAnnotation
          type={AnnotationTypeEnum.CheckBox}
          label="Check Box"
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />
        <DraggableAnnotation
          type={AnnotationTypeEnum.RadioButton}
          label="Radio Button"
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />
        <Divider></Divider>
        <StyledFormTitle style={{paddingBottom: '10px', paddingTop: '10px'}}>Signature fields</StyledFormTitle>
        <DraggableAnnotation
          type={AnnotationTypeEnum.SIGNATURE}
          label="Signature"
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />
        <DraggableAnnotation
          type={AnnotationTypeEnum.INITIAL}
          label="Initial"
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />
        <Box paddingY={2}>
          <TextField
            id="assignee-select"
            inputProps={{
              'data-cy': 'assignee-select',
            }}
            select
            label="Adding signature for"
            value={assignee.email}
            fullWidth
            onChange={userChange}
          >
            {users.map((option: TContact) => (
              <MenuItem key={option.id} value={option.email}>
                {`${option.first_name} ${option.last_name}`}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Divider style={{paddingTop: '10px'}}></Divider>
      </Box>
    </Box>
  );
};

export default SidePanel;


