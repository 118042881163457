import { colors } from '@components/theme/gen2';
import {Box} from "@mui/material";
import {styled} from "@mui/system";
import {rem} from "polished";

export const SidePanelButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  color: colors.grayDark2,
  fontSize: rem(16),
  cursor: 'grab',
  gap: rem(10),
  paddingTop: rem(10),
  paddingBottom: rem(10),
  paddingLeft: rem(10),
  paddingRight: rem(10),
  textTransform: 'unset',
  textAlign: 'left',
  verticalAlign: 'middle',
  borderRadius: 0,
  '&:hover': {
    backgroundColor: colors.grayLight2,
  },
});
